
import { Component, Vue, Watch } from "vue-property-decorator";
import {
    mdiAccount,
    mdiAlert,
    mdiArrowLeftBoldBoxOutline,
    mdiBell,
    mdiCellphoneBasic,
    mdiCheck,
    mdiArrowRightBoldBoxOutline,
    mdiTelevisionClassic,
    mdiLightbulbOnOutline,
    mdiWaterPump,
    mdiSwapHorizontal,
    mdiTelevisionShimmer,
} from "@mdi/js";
import ErrorService, { ValidationErrors } from "@/services/errors";
import Smartcard from "@/models/smartcard";
import SmartcardApi from "@/services/api/smartcard";
import { AxiosError, AxiosResponse } from "axios";
import { SelectItem, VForm } from "@/types/veutify";
import UserApi, {
    UpdateAirtimeSettingsRequest,
    UpdateCamwaterSettingsRequest,
    UpdateCanalplusSettingsRequest,
    UpdateDstvSettingsRequest,
    UpdateEneoSettingsRequest,
    UpdateExchangeSettingsRequest,
    UpdateNotificationSettingsRequest,
    UpdateProfileSettingsRequest,
} from "@/services/api/user";
import { Action, Getter } from "vuex-class";
import { User } from "@/models/user";
import sentry, { captureSentryException } from "@/plugins/sentry";
import { Severity } from "@sentry/types";
import StatusCodes from "http-status-codes";
import firebase from "@/plugins/firebase";
import PhoneNumberApi from "@/services/api/phone-number";
import PhoneNetwork from "@/models/phone-network";
import { addParamsToLocation } from "@/router";
import UserProfile from "@/models/user-profile";
import PriceApi from "@/services/api/prices";
import { ApiResponse } from "@/services/api/axios";

export interface NetworkSelectItem {
    text: string;
    icon: string;
    value: string | number | boolean;
}

@Component
export default class UserProfileEdit extends Vue {
    formEmail: string = "";
    tickIcon: string = mdiCheck;
    alertIcon: string = mdiAlert;
    formName: string = "";
    formSmartCardNumber: string = "";
    formPaymentOption: string = "";
    formPhoneNumber: string = "";
    formExchangeRecipientPhoneNumber: string = "";
    formExchangePhoneNetwork: string = "";
    formExchangeAmount: number = 1000;
    formDstvPackage: string = "CB177";
    formNameConfirmCheckbox: boolean = false;
    formInputErrors: ValidationErrors = new ValidationErrors();
    isSubmitting: boolean = false;
    isValidatingSmartcardNumber = false;
    smartcard: Smartcard | null = null;
    smartCardNumberIsValid = false;
    formIsValid: boolean | null = null;
    activeTab: null | string = null;
    accountIcon: string = mdiAccount;
    dstvIcon: string = mdiTelevisionClassic;
    canalplusIcon: string = mdiTelevisionShimmer;
    exchangeIcon: string = mdiSwapHorizontal;
    airtimeIcon: string = mdiCellphoneBasic;
    camwaterIcon: string = mdiWaterPump;
    notificationIcon: string = mdiBell;
    eneoIcon: string = mdiLightbulbOnOutline;
    isValidatingPhoneNumber = false;
    formRecipientPhoneNumber: string = "";
    formNewsletterEmails = true;
    formDstvReminders = true;
    formAddress = "";
    nextIcon: string = mdiArrowRightBoldBoxOutline;
    prevIcon: string = mdiArrowLeftBoldBoxOutline;
    formCardNumber: string = "";
    formCanalplusPackage: string = "ACCESS";
    formPhoneNetwork = "camtel-cameroon";
    formMeterType = "eneo-prepaid";
    formMeterNumber = "";
    formContractNumber = "";
    canalplusPackages: Array<SelectItem> = [];
    dstvPackages: Array<SelectItem> = [];
    formAmount = 1000;

    @Getter("user") user!: User | null;
    @Getter("userIsAdmin") userIsAdmin!: boolean;
    @Action("setUser") setUser!: (user: User | null) => void;

    get meterTypes(): Array<SelectItem> {
        return [
            {
                value: "eneo-prepay",
                text: "Prepaid",
            },
            {
                value: "eneo-postpay",
                text: "Postpaid",
            },
        ];
    }

    get isPrepaid(): boolean {
        return this.formMeterType === "eneo-prepay";
    }

    get phoneNetwork(): NetworkSelectItem | null {
        const network = this.networks.find(
            (x) => x.value === this.formPhoneNetwork
        );
        return network ?? null;
    }

    get networks(): Array<NetworkSelectItem> {
        return [
            {
                value: "mtn-cameroon",
                icon: "https://s3.amazonaws.com/rld-operator/b589ad8d-f6c0-482e-a596-4360ba55b23d-size-1.png",
                text: "MTN Cameroon",
            },
            {
                value: "orange-cameroon",
                icon: "https://s3.amazonaws.com/rld-operator/fb214865-e85f-47cd-918f-ab69b7953e82-size-1.png",
                text: "Orange Cameroon",
            },
            {
                value: "nexttel-cameroon",
                icon: "https://s3.amazonaws.com/rld-operator/c97f15d8-d7a3-4a2f-87ef-63e863b5ce9b-size-1.png",
                text: "neXttel Cameroon",
            },
            {
                value: "camtel-cameroon",
                icon: "https://s3.amazonaws.com/rld-operator/8be9c8dd-b077-4f85-8d7a-d195749417a3-size-1.png",
                text: "Camtel Cameroon",
            },
            {
                value: "yoomee-cameroon",
                icon: "https://s3.amazonaws.com/rld-operator/13b8ba4d-c07f-400c-9b51-c6b89f65cfbb-size-1.png",
                text: "YooMee Cameroon",
            },
        ];
    }

    get momoNetworks(): Array<NetworkSelectItem> {
        return [
            {
                value: "mtn-cameroon",
                icon: "https://s3.amazonaws.com/rld-operator/b589ad8d-f6c0-482e-a596-4360ba55b23d-size-1.png",
                text: "MTN Mobile Money",
            },
            {
                value: "orange-cameroon",
                icon: "https://s3.amazonaws.com/rld-operator/fb214865-e85f-47cd-918f-ab69b7953e82-size-1.png",
                text: "Orange Money",
            },
        ];
    }

    get yesNoSelectItems(): Array<SelectItem> {
        return [
            {
                value: true,
                text: "Enabled",
            },
            {
                value: false,
                text: "Disabled",
            },
        ];
    }

    loadPrices() {
        PriceApi.indexCanalplus()
            .then((response: AxiosResponse) => {
                this.canalplusPackages = response.data.data;
            })
            .catch(captureSentryException);
        PriceApi.indexDstv()
            .then((response: AxiosResponse) => {
                this.dstvPackages = response.data.data;
            })
            .catch(captureSentryException);
    }

    get hasPhoneNetwork(): boolean {
        return this.phoneNetwork !== null;
    }

    get exchangePhoneIcon(): string | null {
        const network = this.momoNetworks.find(
            (x) => x.value === this.formExchangePhoneNetwork
        );
        if (!network) {
            return null;
        }
        return network.icon;
    }

    get formPhoneNumberLabel(): string {
        if (!this.formPaymentOption) {
            return "Payment Phone number";
        }
        if (this.formPaymentOption == "mtn-mobile-money") {
            return "MTN Mobile Money Phone Number";
        }
        return "Orange Money Phone Number";
    }

    get formPhoneNumberPlaceholder(): string {
        if (!this.formPaymentOption) {
            return "Default phone number e.g 6xxxxxxxx";
        }
        return `Default ${this.paymentMethodString} number e.g 6xxxxxxxx`;
    }

    get paymentMethodString(): string {
        return this.$options.filters?.capitalize(
            this.formPaymentOption.split("-").join(" ")
        );
    }

    @Watch("activeTab")
    onChildChanged(tab: string) {
        addParamsToLocation(this.$route, { tab });
    }

    onNotificationSettingsUpdate(event: Event) {
        event.preventDefault();
        this.clearValidationErrors();
        (this.$refs["notificationSettingsForm"] as VForm).validate();
        if (!this.formIsValid) {
            return;
        }

        this.isSubmitting = true;
        this.$recaptcha("update_notification_settings")
            .then((captcha: string) => {
                this.updateNotificationSettings(this.user?.uid as string, {
                    captcha: captcha,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    dstv_reminder: this.formDstvReminders,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    newsletter_email: this.formNewsletterEmails,
                });
            })
            .catch(this.handleError);
    }

    onAirtimeSettingsUpdate(event: Event) {
        event.preventDefault();
        this.clearValidationErrors();
        (this.$refs["airtimeSettingsForm"] as VForm).validate();
        if (!this.formIsValid) {
            return;
        }

        this.isSubmitting = true;
        this.$recaptcha("update_airtime_settings")
            .then((captcha: string) => {
                this.updateAirtimeSettings(this.user?.uid as string, {
                    captcha: captcha,
                    amount: parseInt(this.formAmount.toString()),
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    phone_network: this.formPhoneNetwork,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    recipient_phone_number: this.formRecipientPhoneNumber,
                });
            })
            .catch(this.handleError);
    }

    onExchangeSettingsUpdate(event: Event) {
        event.preventDefault();
        this.clearValidationErrors();
        (this.$refs["exchangeSettingsForm"] as VForm).validate();
        if (!this.formIsValid) {
            return;
        }

        this.isSubmitting = true;
        this.$recaptcha("update_exchange_settings")
            .then((captcha: string) => {
                this.updateExchangeSettings(this.user?.uid as string, {
                    captcha: captcha,
                    amount: parseInt(this.formExchangeAmount.toString()),
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    phone_network: this.formExchangePhoneNetwork,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    recipient_phone_number:
                        this.formExchangeRecipientPhoneNumber,
                });
            })
            .catch(this.handleError);
    }

    onEneoSettingsUpdate(event: Event) {
        event.preventDefault();
        this.clearValidationErrors();
        (this.$refs["eneoSettingsForm"] as VForm).validate();
        if (!this.formIsValid) {
            return;
        }

        this.isSubmitting = true;
        this.$recaptcha("update_eneo_settings")
            .then((captcha: string) => {
                const request: UpdateEneoSettingsRequest = {
                    captcha: captcha,
                    amount: parseInt(this.formAmount.toString()),
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    meter_type: this.formMeterType,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    meter_number: this.formMeterNumber,
                };
                this.updateEneoSettings(this.user?.uid as string, request);
            })
            .catch(this.handleError);
    }

    onCamwaterSettingsUpdate(event: Event) {
        event.preventDefault();
        this.clearValidationErrors();
        (this.$refs["camwaterSettingsForm"] as VForm).validate();
        if (!this.formIsValid) {
            return;
        }

        this.isSubmitting = true;
        this.$recaptcha("update_camwater_settings")
            .then((captcha: string) => {
                const request: UpdateCamwaterSettingsRequest = {
                    captcha: captcha,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    contract_number: this.formContractNumber,
                };
                this.updateCamwaterSettings(this.user?.uid as string, request);
            })
            .catch(this.handleError);
    }

    onDStvSettingsUpdate(event: Event) {
        event.preventDefault();
        this.clearValidationErrors();
        (this.$refs["dstvSettingsForm"] as VForm).validate();
        if (!this.formIsValid) {
            return;
        }

        this.isSubmitting = true;
        this.$recaptcha("update_dstv_settings")
            .then((captcha: string) => {
                this.updateDStvSettings(this.user?.uid as string, {
                    captcha: captcha,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    dstv_package_id: this.formDstvPackage,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    smartcard_number: this.formSmartCardNumber,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    name_confirmation: this.formNameConfirmCheckbox,
                });
            })
            .catch(this.handleError);
    }

    onCanalplusSettingsUpdate(event: Event) {
        event.preventDefault();
        this.clearValidationErrors();
        (this.$refs["canalplusSettingsForm"] as VForm).validate();
        if (!this.formIsValid) {
            return;
        }

        this.isSubmitting = true;
        this.$recaptcha("update_canalplus_settings")
            .then((captcha: string) => {
                this.updateCanalplusSettings(this.user?.uid as string, {
                    captcha: captcha,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    canalplus_package: this.formCanalplusPackage,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    card_number: this.formCardNumber,
                });
            })
            .catch(this.handleError);
    }

    onProfileSettingsUpdate(event: Event) {
        event.preventDefault();
        this.clearValidationErrors();
        (this.$refs["profileSettingsForm"] as VForm).validate();
        if (!this.formIsValid) {
            return;
        }

        this.isSubmitting = true;
        this.$recaptcha("update_profile_settings")
            .then((captcha: string) => {
                this.updateProfileSettings(this.user?.uid as string, {
                    captcha: captcha,
                    name: this.formName,
                    email: this.formEmail,
                    address: this.formAddress,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    payment_method: this.formPaymentOption,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    payment_phone_number: this.formPhoneNumber,
                });
            })
            .catch(this.handleError);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleError(error: any) {
        sentry.captureMessage(JSON.stringify(error), Severity.Error);
        this.$root.$emit(
            this.$constants.NOTIFICATION_EVENTS.ERROR,
            error?.message
                ? error.message
                : "There was an error while updating your profile"
        );
    }

    updateEneoSettings(userId: string, payload: UpdateEneoSettingsRequest) {
        UserApi.updateEneoSettings(userId, payload)
            .then((response: AxiosResponse) => {
                this.$root.$emit(
                    this.$constants.NOTIFICATION_EVENTS.SUCCESS,
                    response.data.message ??
                        "ENEO settings updated successfully."
                );
            })
            .catch(this.handleAxiosError)
            .finally(() => {
                this.isSubmitting = false;
            });
    }

    updateCamwaterSettings(
        userId: string,
        payload: UpdateCamwaterSettingsRequest
    ) {
        UserApi.updateCamwaterSettings(userId, payload)
            .then((response: AxiosResponse) => {
                this.$root.$emit(
                    this.$constants.NOTIFICATION_EVENTS.SUCCESS,
                    response.data.message ??
                        "Camwater settings updated successfully."
                );
            })
            .catch(this.handleAxiosError)
            .finally(() => {
                this.isSubmitting = false;
            });
    }

    updateAirtimeSettings(
        userId: string,
        payload: UpdateAirtimeSettingsRequest
    ) {
        UserApi.updateAirtimeSettings(userId, payload)
            .then((response: AxiosResponse) => {
                this.$root.$emit(
                    this.$constants.NOTIFICATION_EVENTS.SUCCESS,
                    response.data.message ??
                        "Airtime settings updated successfully."
                );
            })
            .catch(this.handleAxiosError)
            .finally(() => {
                this.isSubmitting = false;
            });
    }

    updateExchangeSettings(
        userId: string,
        payload: UpdateExchangeSettingsRequest
    ) {
        UserApi.updateExchangeSettings(userId, payload)
            .then((response: AxiosResponse) => {
                this.$root.$emit(
                    this.$constants.NOTIFICATION_EVENTS.SUCCESS,
                    response.data.message ??
                        "Exchange settings updated successfully."
                );
            })
            .catch(this.handleAxiosError)
            .finally(() => {
                this.isSubmitting = false;
            });
    }

    updateNotificationSettings(
        userId: string,
        payload: UpdateNotificationSettingsRequest
    ) {
        UserApi.updateNotificationSettings(userId, payload)
            .then((response: AxiosResponse) => {
                this.$root.$emit(
                    this.$constants.NOTIFICATION_EVENTS.SUCCESS,
                    response.data.message ??
                        "Notification settings updated successfully."
                );
            })
            .catch(this.handleAxiosError)
            .finally(() => {
                this.isSubmitting = false;
            });
    }

    updateDStvSettings(userId: string, payload: UpdateDstvSettingsRequest) {
        UserApi.updateDstvSettings(userId, payload)
            .then((response: AxiosResponse) => {
                this.$root.$emit(
                    this.$constants.NOTIFICATION_EVENTS.SUCCESS,
                    response.data.message ??
                        "DStv settings updated successfully."
                );
            })
            .catch(this.handleAxiosError)
            .finally(() => {
                this.isSubmitting = false;
            });
    }

    updateCanalplusSettings(
        userId: string,
        payload: UpdateCanalplusSettingsRequest
    ) {
        UserApi.updateCanalplusSettings(userId, payload)
            .then((response: AxiosResponse) => {
                this.$root.$emit(
                    this.$constants.NOTIFICATION_EVENTS.SUCCESS,
                    response.data.message ??
                        "CANAL+ settings updated successfully."
                );
            })
            .catch(this.handleAxiosError)
            .finally(() => {
                this.isSubmitting = false;
            });
    }

    updateProfileSettings(
        userId: string,
        payload: UpdateProfileSettingsRequest
    ) {
        UserApi.updateProfileSettings(userId, payload)
            .then((response: AxiosResponse) => {
                this.$root.$emit(
                    this.$constants.NOTIFICATION_EVENTS.SUCCESS,
                    response.data.message ??
                        "Your profile has been updated successfully"
                );
                firebase
                    .auth()
                    .currentUser?.reload()
                    .then(() => {
                        this.setUser(firebase.auth().currentUser);
                    });
            })
            .catch(this.handleAxiosError)
            .finally(() => {
                this.isSubmitting = false;
            });
    }

    clearValidationErrors() {
        this.formInputErrors = new ValidationErrors();
    }

    validateSmartcardNumber() {
        this.clearValidationErrors();
        if (!this.formSmartCardNumber) {
            return;
        }

        this.isValidatingSmartcardNumber = true;
        this.formNameConfirmCheckbox = false;
        this.smartcard = null;
        this.smartCardNumberIsValid = false;

        this.$recaptcha("validate_smartcard")
            .then((token: string) => {
                SmartcardApi.create(this.formSmartCardNumber, token)
                    .then((response: AxiosResponse) => {
                        this.formInputErrors.clear("smartcard_number");
                        this.smartcard = new Smartcard(response.data.data);
                        this.smartCardNumberIsValid = true;
                    })
                    .catch(this.handleAxiosError)
                    .finally(() => {
                        this.isValidatingSmartcardNumber = false;
                    });
            })
            .catch((error: AxiosError<ApiResponse>) => {
                this.isValidatingSmartcardNumber = false;
                this.handleAxiosError(error);
            });
    }

    setProfileFromAuthUser() {
        this.formEmail = this.user?.email || "";
        this.formName = this.user?.displayName || "";
    }

    initProfile(userProfile: UserProfile): void {
        this.formDstvPackage = userProfile.dstvPackageID ?? "";
        this.formPaymentOption = userProfile.paymentMethod ?? "";
        this.formPhoneNumber = userProfile.paymentPhoneNumber ?? "";
        this.formSmartCardNumber = userProfile.smartcardNumber ?? "";
        this.formName = userProfile.name ?? this.user?.displayName ?? "";
        this.formAddress = userProfile.profile?.address ?? this.formAddress;
        this.formEmail = userProfile.email ?? this.user?.email ?? "";
        this.formAmount = userProfile.airtime?.amount ?? this.formAmount;
        this.formPhoneNetwork =
            userProfile?.airtime?.phoneNetwork ?? this.formPhoneNetwork;
        this.formRecipientPhoneNumber =
            userProfile.airtime?.recipientPhoneNumber ??
            this.formRecipientPhoneNumber;
        this.formNewsletterEmails =
            userProfile.notification?.newsletterEmail ??
            this.formNewsletterEmails;
        this.formDstvReminders =
            userProfile.notification?.dstvReminder ?? this.formDstvReminders;
        this.formMeterType = userProfile.eneo?.meterType ?? this.formMeterType;
        this.formMeterNumber =
            userProfile.eneo?.meterNumber ?? this.formMeterNumber;

        this.formExchangeAmount =
            userProfile.exchange?.amount ?? this.formExchangeAmount;
        this.formExchangeRecipientPhoneNumber =
            userProfile.exchange?.recipientPhoneNumber ??
            this.formExchangeRecipientPhoneNumber;
        this.formExchangePhoneNetwork =
            userProfile.exchange?.phoneNetwork ?? this.formExchangePhoneNetwork;
        this.formCanalplusPackage =
            userProfile.canalplus?.canalplusPackageId ??
            this.formCanalplusPackage;
        this.formCardNumber =
            userProfile.canalplus?.cardNumber ?? this.formCardNumber;
    }

    loadProfile() {
        this.isSubmitting = true;
        UserApi.getProfile(this.user?.uid as string)
            .then((response: AxiosResponse) => {
                this.initProfile(new UserProfile(response.data.data));
                if (this.formSmartCardNumber) {
                    setTimeout(() => {
                        this.validateSmartcardNumber();
                    }, 1000);
                }
            })
            .catch((error: AxiosError<ApiResponse>) => {
                if (error.response?.status === StatusCodes.NOT_FOUND) {
                    this.setProfileFromAuthUser();
                    return;
                }
                this.handleAxiosError(error);
            })
            .finally(() => {
                this.isSubmitting = false;
            });
    }
    handleAxiosError(error: AxiosError<ApiResponse>) {
        this.formInputErrors = ErrorService.getValidationErrors(error);
        this.$root.$emit(
            this.$constants.NOTIFICATION_EVENTS.ERROR,
            error.response?.data?.message ?? error.message
        );
    }

    onExchangePhoneNumberChanged() {
        this.validatePhoneNumber(this.formExchangeRecipientPhoneNumber);
    }

    onAirtimePhoneNumberChanged() {
        this.validatePhoneNumber(this.formPhoneNumber);
    }

    validatePhoneNumber(phoneNumber: string) {
        this.isValidatingPhoneNumber = true;
        this.$recaptcha("validate_phone_number")
            .then((token: string) => {
                PhoneNumberApi.store(phoneNumber, token)
                    .then((response: AxiosResponse) => {
                        this.formInputErrors.clear("recipient_phone_number");
                        const phoneNetwork = new PhoneNetwork(
                            response.data.data
                        );
                        this.formPhoneNetwork = phoneNetwork.id;
                        this.formExchangePhoneNetwork = phoneNetwork.id;
                    })
                    .catch(this.handleAxiosError)
                    .finally(() => {
                        this.isValidatingPhoneNumber = false;
                    });
            })
            .catch((error) => {
                this.isValidatingPhoneNumber = false;
                this.handleError(error);
            });
    }

    mounted() {
        this.loadProfile();
        this.loadPrices();
        const tab = this.$route.query.tab as string;
        if (tab && tab.length >= 0) {
            this.activeTab = tab;
        }
    }
}
